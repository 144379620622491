import { Registerform } from '../components/auth/Register';
import { Layoutsimple } from '../components/layout/Simple';
export const Register = () => {
       
        return (
            <Layoutsimple>
                <Registerform />
            </Layoutsimple>
          
        );
 }