import { OldPassword } from '../components/auth/OldPassword';
import { Layoutsimple } from '../components/layout/Simple';
export const ForgotPassword = () => {
       
        return (
            <Layoutsimple>
                <OldPassword />
            </Layoutsimple>
          
        );
 }